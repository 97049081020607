import request from "axios"

export default {
  /**
   * @description 获取分类列表
   * @param params
   * @return {Promise<*>}
   */
  async getTypeList(params) {
    return await request.post("/api/type/list", params)
  },
  /**
   * @description 编辑指定分类信息
   * @param params
   * @return {Promise<*>}
   */
  async editTypeInfo(params) {
    return await request.post("/api/type/update", params)
  },
  /**
   * @description 删除指定分类信息
   * @param params
   * @return {Promise<*>}
   */
  async deleteTypeInfo(params) {
    return await request.post("/api/type/delete", params)
  },
  /**
   * @description 添加新的分类信息
   * @param params
   * @return {Promise<*>}
   */
  async addTypeInfo(params) {
    return await request.post("/api/type/add", params)
  }
}
