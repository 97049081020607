const type = [
  {
    name: "typeList",
    path: "/type",
    component: () => import("@/views/type"),
    meta: {
      title: "分类管理",
      belong: "typeManage"
    }
  }
]
export default type;
