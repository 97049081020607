<template>
  <div class="l-left-menu">
    <img src="@/assets/menuBg.jpg" class="l-left-menu-bg">
    <div class="l-left-menu-container">
      <div
        class="l-left-menu-item"
        v-for="item in list"
        :key="item.id"
        :class="{'active': item.id === $route.meta.belong }"
        @click="changePage(item.page)"
      >
        <l-icon :name="item.icon"></l-icon>
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      list: []
    }
  },
  methods: {
    /**
     * @description 获取菜单列表
     */
    getMenuList() {
      this.list = [
        {
          id: "articleManage",
          name: "文章管理",
          page: "/article",
          icon: "icon-navicon-wzgl"
        },
        {
          id: "typeManage",
          name: "分类管理",
          page: "/type",
          icon: "icon-fenleiguanli"
        },
        {
          id: "tagManage",
          name: "标签管理",
          page: "/tag",
          icon: "icon-biaoqianguanli"
        },
        {
          id: "resumeManage",
          name: "简历管理",
          page: "/resume",
          icon: "icon-resume"
        }
      ]
    },
    /**
     * @description 切换页面
     * @param routerPath
     */
    changePage(routerPath) {
      if (routerPath !== this.$route.path) {
        this.$router.push({
          path: routerPath
        })
      }
    }
  },
  created() {
    this.getMenuList();
  }
}
</script>

<style lang="scss">
  .l-left-menu {
    height: 100%;
    width: 300px;
    position: relative;

    .l-left-menu-container {
      background-color: #4bb297b5;
      position: relative;
      z-index: 2;
      height: 100%;

      .l-left-menu-item {
        line-height: 16px;
        font-size: 14px;
        padding: 17px 20px;
        cursor: pointer;
        transition: 0.3s ease-in-out all;

        .l-icon {
          width: 16px;
          height: 16px;
          margin-right: 5px;
          transition: ease-in-out all 0.3s;
        }

        &:hover,
        &.active {
          background-color: rgba(0, 0, 0, 0.5);
          color: #4bb297;

          .l-icon {
            color: #4bb297;
          }
        }
      }
    }

    .l-left-menu-bg {
      height: 100%;
      width: 300px;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
    }
  }
</style>
