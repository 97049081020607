import Vue from "vue";
import axios from "axios";
import {Notification} from "element-ui"
import {Loading} from 'element-ui';

var loadingInstance;

/**
 * 获取请求响应过程中出现的错误,比如401,404或其他错误码
 *
 * @param {*} error
 * @returns
 */
function resError(error) { // 该处是请求访问出错时候的处理
  if (error.response) {
    switch (error.response.status) {
      case 404:
        // 返回 401 清除token信息并跳转到登录页面
        // 该处还差一个清除token的过程
        Notification({
          type: "warning",
          title: "请求出错",
          message: "服务忙，请稍后重试"
        });
        break;
      case 401:
        Notification({
          type: "warning",
          title: "请求出错",
          message: "服务忙，请稍后重试"
        });
        break;
      case 500:
        Notification({
          type: "warning",
          title: "请求出错",
          message: "服务忙，请稍后重试"
        });
        break;
      default:
        setTimeout(() => {
          loadingInstance.close();
        }, 1000)
    }
  }
  var res = "未知错误";
  if (error.response) {
    res = error.response.status;
  }
  return Promise.reject(res); // 返回接口返回的错误信息
}

// 该列表是处理响应
const resInterceptList = [
  function (config) {
    setTimeout(() => {
      loadingInstance.close();
    }, 1000)
    if (config.data.isSuccess) {
      return config.data.data ? config.data.data : true;
    } else {
      if (!config.data.isSuccess) {
        Message.Message({
          showClose: true,
          message: config.data.message,
          type: "error"
        });
      }
      return false;
    }
  }
];

/**
 * 定义请求拦截器使用过程中出现的错误捕获
 *
 * @param {*} error
 * @returns
 */
function reqError(error) {
  return Promise.reject(error);
}

const reqInterceptList = [
  function (config) {
    loadingInstance = Loading.service({
      fullscreen: true,
      background: "rgba(0,0,0,0.8)",
      text: "数据请求中"
    })
    let req = "";
    for (let i in config.data) {
      if (typeof config.data[i] === "object") {
        req += encodeURIComponent(i) + "=" + encodeURIComponent(JSON.stringify(config.data[i])) + "&";
      } else {
        req += encodeURIComponent(i) + "=" + encodeURIComponent(config.data[i]) + "&";
      }
    }
    config.data = req;
    return config;
  }
];

// 增加请求拦截器,主要用于本地校验token是否合法以及token是否有相关的访问权限
for (let reqRule of reqInterceptList) {
  axios.interceptors.request.use(reqRule, reqError);
}

for (let resRule of resInterceptList) {
  axios.interceptors.response.use(resRule, resError);
}

const api = {};

/**
 * 深度赋值属性
 *
 * @param {*} obj
 * @param {*} path
 * @param {*} value
 * @returns
 */
function property(obj, path, value) {
  if (path.indexOf("/") >= 0) {
    const list = path.split("/");
    const key = list[0];
    list.splice(0, 1);
    let newPath;
    if (list.length <= 1) {
      newPath = list[0];
    } else {
      newPath = list.join("/");
    }
    obj[key] = obj[key] || {};
    property(obj[key], newPath, value);
  } else {
    obj[path] = value;
    return obj;
  }
}

const apiMethods = require.context(
  // 其filter目录的相对路径
  "../api",
  // 是否查询其子目录
  true,
  // 匹配基础filter包名
  /\w+\.js$/
);
apiMethods.keys().forEach(fileName => {
  // 获取api包名
  const apiMethod = apiMethods(fileName);
  // 剥去文件名开头的 `./` 和结尾的扩展名
  const apiName = fileName.replace(/^\.\/(.*)\.\w+$/, "$1");
  const func = apiMethod.default || apiMethod;
  property(api, apiName, func);
});

Vue.prototype.$api = api;
