import request from "axios";

export default {
  /**
   * @description 获取简历信息
   * @returns {Promise<*>}
   */
  async getResumeInfo() {
    return await request.post("/api/resume/details");
  },
  /**
   * @description 更新简历信息
   * @returns {Promise<*>}
   */
  async updateResumeInfo(params) {
    return await request.post("/api/resume/save", params);
  }
};
