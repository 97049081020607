import request from "axios"

export default {
  /**
   * @description 获取标签列表
   * @param params
   * @return {Promise<*>}
   */
  async getTagList(params) {
    return await request.post("/api/tag/list", params)
  },
  /**
   * @description 编辑指定标签信息
   * @param params
   * @return {Promise<*>}
   */
  async editTagInfo(params) {
    return await request.post("/api/tag/edit", params)
  },
  /**
   * @description 删除指定标签信息
   * @param params
   * @return {Promise<*>}
   */
  async deleteTagInfo(params) {
    return await request.post("/api/tag/delete", params)
  },
  /**
   * @description 添加新的标签信息
   * @param params
   * @return {Promise<*>}
   */
  async addTagInfo(params) {
    return await request.post("/api/tag/add", params)
  }
}
