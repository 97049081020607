<template>
  <div class="l-title-card">
    <l-icon
      v-if="needBack || needBack === 'true'"
      name="icon-fanhui"
      class="l-title-card-back"
      @click.native="back"
    >
    </l-icon>
    <!-- 模块名称 -->
    <div class="l-title-card-name">
      {{ name }}
    </div>
    <!-- 名称左侧组件插槽 -->
    <div class="l-title-card-left-componlt">
      <slot name="left"></slot>
    </div>
    <!-- 模块名称组件右侧内容区域 -->
    <div class="l-title-card-right-container">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "lTitleCard",
  props: {
    name: {
      type: String,
      default: ""
    },
    needBack: {
      type: [Boolean, String],
      default: false
    },
    backName: {
      type: String,
      default: ""
    }
  },
  methods: {
    /**
     * @description 点击回退按钮，触发回调事件
     */
    back() {
      if (this.backName) {
        this.$router.push({
          name: this.backName
        })
      } else {
        window.history(-1)
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  .l-title-card {
    width: 100%;
    height: 50px;
    line-height: 50px;
    border-radius: 4px 4px 0 0;
    background-color: #ffffff;
    border-bottom: 1px solid #dce5ec;
    text-align: left;
    padding: 0 20px;
    box-sizing: border-box;
    user-select: none;
    position: relative;
    z-index: 3;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.1);

    .l-title-card-back {
      position: relative;
      color: #333333;
      cursor: pointer;
      width: 14px;
      height: 14px;
      transition: 0.3s ease-in-out all;

      &:hover {
        color: #4694df;
      }
    }

    .l-title-card-name {
      font-size: 14px;
      color: #333333;
      font-weight: bold;
      display: inline;
    }

    .l-title-card-left-componlt {
      display: inline;
      margin-left: 12px;

      div {
        display: inline-block;
      }
    }

    .l-title-card-right-container {
      float: right;
      padding: 9px 0;
      font-size: 12px;
      display: flex;
      justify-content: flex-start;

      .el-button {
        height: 32px;
        padding: 6px 20px;
      }
    }
  }
</style>
