const resume = [
  {
    path: "/resume",
    name: "resumeManage",
    component: () => import("@/views/resume"),
    meta: {
      title: "简历管理",
      belong: "resumeManage"
    }
  }
]
export default resume
