import request from "axios"

export default {
  /**
   * @description 获取文章列表
   * @param params
   * @return {Promise<*>}
   */
  async getArticleList(params) {
    return await request.post("/api/article/list", params)
  },
  /**
   * @description 编辑指定文章信息
   * @param params
   * @return {Promise<*>}
   */
  async editArticleInfo(params) {
    return await request.post("/api/article/update", params)
  },
  /**
   * @description 获取文章详情
   * @param params
   * @return {Promise<*>}
   */
  async getArticleDetails(params) {
    return await request.post("/api/article/details", params)
  },
  /**
   * @description 删除指定文章信息
   * @param params
   * @return {Promise<*>}
   */
  async deleteArticleInfo(params) {
    return await request.post("/api/article/delete", params)
  },
  /**
   * @description 添加新的文章信息
   * @param params
   * @return {Promise<*>}
   */
  async addArticleInfo(params) {
    return await request.post("/api/article/add", params)
  },
  /**
   * @description 获取上传凭证
   * @return {Promise<*>}
   */
  async getUploadToken() {
    return request.get("/api/article/getUploadToken")
  }
}
