const tag = [
  {
    path: "/tag",
    name: "tagList",
    component: () => import("@/views/tag"),
    meta: {
      title: "标签管理",
      belong: "tagManage"
    }
  }
]
export default tag
