<template>
  <div id="app">
    <l-left-menu>
    </l-left-menu>
    <router-view class="main-container">
    </router-view>
  </div>
</template>

<script>
export default {
  name: "App"
}
</script>

<style lang="scss">
  .hljs {
    background-color: transparent !important;
  }
</style>
