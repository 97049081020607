import Vue from "vue"
import VueRouter from "vue-router"

Vue.use(VueRouter);
let routes = []
// 将 src 文件夹下遍历得到的的路由文件，存储至routeFiles数组内
const routeFiles = require.context(
  "./src",
  true,
  /.js$/
);

// 遍历数组，将路由文件内的路由数据合并至数组内
routeFiles.keys().forEach((key) => {
  routes = [...routes, ...routeFiles(key).default];
});

const router = new VueRouter({
  mode: "history",
  routes
});

router.beforeEach((to, from, next) => {
  // 此处是为了避免同路由作死的点，导致Console报错。个人强迫症，可无视
  if (to.path === from.path) {
    return
  }
  next();
});

export default router;
