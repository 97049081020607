const article = [
  {
    path: "/",
    redirect: "/article"
  },
  {
    name: "articleList",
    path: "/article",
    component: () => import("@/views/article"),
    meta: {
      title: "文章管理",
      belong: "articleManage"
    }
  },
  {
    name: "addArticle",
    path: "/add",
    component: () => import("@/views/article/add"),
    meta: {
      title: "添加文章",
      belong: "articleManage"
    }
  },
  {
    name: "editArticle",
    path: "/edit/:id",
    component: () => import("@/views/article/edit"),
    meta: {
      title: "添加文章",
      belong: "articleManage"
    }
  }
]

export default article
